import React, { Component } from "react";
import "./Footer.css";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footer-container">
        <div className="footer-text-container">
          <p>
            © Copyright 2011 - {new Date().getFullYear()} | Ateliér DV s.r.o.,
            Hornopotočná 20, 917 01 Trnava
          </p>
          <p>
            Website by{" "}
            <a
              className="email-me-link link-no-decor"
              href="mailto:d.vagala@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dominik Vagala
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
