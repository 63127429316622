

function importAll(r) {
  return r.keys().map(r);
}

export function getListOfImages(project_id) {
  switch (project_id) {

    case "adalbertinum":
      return importAll(
        require.context(
          "../../../../public/projects/adalbertinum/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "administrativna_budova_boge":
      return importAll(
        require.context(
          "../../../../public/projects/administrativna_budova_boge/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "alzbetin_park":
      return importAll(
        require.context(
          "../../../../public/projects/alzbetin_park/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "amfiteater":
      return importAll(
        require.context(
          "../../../../public/projects/amfiteater/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "ave_galanta":
      return importAll(
        require.context(
          "../../../../public/projects/ave_galanta/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "biskupice":
      return importAll(
        require.context(
          "../../../../public/projects/biskupice/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "botanicka":
      return importAll(
        require.context(
          "../../../../public/projects/botanicka/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "centrum_hurbanova":
      return importAll(
        require.context(
          "../../../../public/projects/centrum_hurbanova/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "coffea_trakovice":
      return importAll(
        require.context(
          "../../../../public/projects/coffea_trakovice/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "coffea_trnava":
      return importAll(
        require.context(
          "../../../../public/projects/coffea_trnava/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "dolna_brana":
      return importAll(
        require.context(
          "../../../../public/projects/dolna_brana/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "dom_na_pekarskej":
      return importAll(
        require.context(
          "../../../../public/projects/dom_na_pekarskej/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "dom_nadeje":
      return importAll(
        require.context(
          "../../../../public/projects/dom_nadeje/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "evanielicky_kostol":
      return importAll(
        require.context(
          "../../../../public/projects/evanielicky_kostol/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "farsky_urad":
      return importAll(
        require.context(
          "../../../../public/projects/farsky_urad/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "hala_stitare":
      return importAll(
        require.context(
          "../../../../public/projects/hala_stitare/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "hornopotocna":
      return importAll(
        require.context(
          "../../../../public/projects/hornopotocna/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "hradby_trnava":
      return importAll(
        require.context(
          "../../../../public/projects/hradby_trnava/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "informacny_system_kopcany":
      return importAll(
        require.context(
          "../../../../public/projects/informacny_system_kopcany/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "kastiel_hubice":
      return importAll(
        require.context(
          "../../../../public/projects/kastiel_hubice/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "kaufland":
      return importAll(
        require.context(
          "../../../../public/projects/kaufland/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "koniarekova_1":
      return importAll(
        require.context(
          "../../../../public/projects/koniarekova_1/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "koniarekova_2":
      return importAll(
        require.context(
          "../../../../public/projects/koniarekova_2/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "kostnica_karner":
      return importAll(
        require.context(
          "../../../../public/projects/kostnica_karner/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "kostol_belusa":
      return importAll(
        require.context(
          "../../../../public/projects/kostol_belusa/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "kreativne_centrum":
      return importAll(
        require.context(
          "../../../../public/projects/kreativne_centrum/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "maly_pariz":
      return importAll(
        require.context(
          "../../../../public/projects/maly_pariz/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "materska_skola_ivanka":
      return importAll(
        require.context(
          "../../../../public/projects/materska_skola_ivanka/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "mestsky_priemyselny_park":
      return importAll(
        require.context(
          "../../../../public/projects/mestsky_priemyselny_park/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "modra_harmonia":
      return importAll(
        require.context(
          "../../../../public/projects/modra_harmonia/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "motel_karpaty":
      return importAll(
        require.context(
          "../../../../public/projects/motel_karpaty/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "nyarovska_kuria":
      return importAll(
        require.context(
          "../../../../public/projects/nyarovska_kuria/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "objekt_frantiskanska":
      return importAll(
        require.context(
          "../../../../public/projects/objekt_frantiskanska/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "objekt_sucha":
      return importAll(
        require.context(
          "../../../../public/projects/objekt_sucha/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "pastoracne_centrum":
      return importAll(
        require.context(
          "../../../../public/projects/pastoracne_centrum/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "pohoda":
      return importAll(
        require.context(
          "../../../../public/projects/pohoda/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "radnica_skalica":
      return importAll(
        require.context(
          "../../../../public/projects/radnica_skalica/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "revitalizacia_trnovec":
      return importAll(
        require.context(
          "../../../../public/projects/revitalizacia_trnovec/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "revitalizacia_voderady":
      return importAll(
        require.context(
          "../../../../public/projects/revitalizacia_voderady/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "revitalizacia_zelenec":
      return importAll(
        require.context(
          "../../../../public/projects/revitalizacia_zelenec/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "rodinny_dom_casta":
      return importAll(
        require.context(
          "../../../../public/projects/rodinny_dom_casta/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "snp":
      return importAll(
        require.context(
          "../../../../public/projects/snp/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "sportova_hala":
      return importAll(
        require.context(
          "../../../../public/projects/sportova_hala/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "spp_nove_mesto":
      return importAll(
        require.context(
          "../../../../public/projects/spp_nove_mesto/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "tanex":
      return importAll(
        require.context(
          "../../../../public/projects/tanex/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "tulipan_kaplnka":
      return importAll(
        require.context(
          "../../../../public/projects/tulipan_kaplnka/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "vodaren":
      return importAll(
        require.context(
          "../../../../public/projects/vodaren/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "voderady":
      return importAll(
        require.context(
          "../../../../public/projects/voderady/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "zatvor":
      return importAll(
        require.context(
          "../../../../public/projects/zatvor/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );

    case "zzz_mock":
      return importAll(
        require.context(
          "../../../../public/projects/zzz_mock/project_photos/",
          false,
          /\.(png|jpe?g|JPG|svg)$/
        )
      );
  }
}
