import React, { Component } from "react";
import "./Menu.css";
import { Link as ScrollLink } from "react-scroll";

class Menu extends Component {
  state = {};

  menuItemClicked(where) {
    if (window.location.pathname !== "/" && window.location.pathname !== "") {
      if (where === "projects-section") {
        console.log("to_all-projects-" + window.location.pathname.substring(1));
        window.location.href =
          "to_all-projects-" + window.location.pathname.substring(1);
      } else {
        window.location.href = "to_" + where;
      }
    }
  }

  render() {
    return (
      <div className="menu-container-square">
        <div className="menu-container">
          <ScrollLink
            className="menu-item"
            activeClass="active"
            to="contact-section"
            spy={true}
            smooth={true}
            offset={-280}
            duration={500}
            onClick={() => this.menuItemClicked("contact-section")}
          >
            <div id="text-with-line" >
              <h5>Kontakt</h5>
              <div className="menu-item-line" />
            </div>
          </ScrollLink>
          <ScrollLink
            className="menu-item"
            activeClass="active"
            to="projects-section"
            spy={true}
            smooth={true}
            offset={-180}
            duration={500}
            onClick={() => this.menuItemClicked("projects-section")}
          >
            <div id="text-with-line" >
              <h5>Projekty</h5>
              <div className="menu-item-line" />
            </div>
          </ScrollLink>{" "}
          <ScrollLink
            className="menu-item"
            activeClass="active"
            to="about-section"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onClick={() => this.menuItemClicked("about-section")}
          >
            <div id="text-with-line" >
              <h5>O nás</h5>
              <div className="menu-item-line" />
            </div>
          </ScrollLink>
          <div className="menu-top-line"></div>
        </div>
      </div>
    );
  }
}

export default Menu;
