import React, { Component } from "react";
import "./HeadingBlocks.css";
import "./FirstHeadingBlock.css";
import ReactHtmlParser from "react-html-parser";

class FirstHeadingBlock extends Component {
  state = {};
  render() {
    return (
      <div
        className={`h1-white-back-container first-heading ${
          this.props.project_detail && "project-detail-heading"
        }`}
      >
        <div className="h1-container">
          <h1>{ReactHtmlParser(this.props.heading)}</h1>
        </div>
        {this.props.text && (
          <div className="h3-container">
            <h3 className="with-br">{ReactHtmlParser(this.props.text)}</h3>
            <h3 className="without-br">
              {ReactHtmlParser(this.props.text.replace("<br/>", ""))}
            </h3>
          </div>
        )}
      </div>
    );
  }
}

export default FirstHeadingBlock;
