import React, { Component } from "react";
import { projects } from "../../../data/data";
import "./ProjectsSection.css";

class ProjectsSection extends Component {
  constructor(props) {
    super(props);

    var dict = {};

    for (let i = 0; i < projects.length; i++) {
      for (let j = 0; j < projects[i].projects.length; j++) {
        dict[projects[i].projects[j]] = false;
      }
    }

    this.state = dict;

    this.onThumbnailTouchStart = this.onThumbnailTouchStart.bind(this);
  }

  onThumbnailTouchStart(id) {
    console.log(id);

    if (this.touchedThumbnail !== id) {
      this.setState({ [id]: true, [this.touchedThumbnail]: false });
    }

    this.touchedThumbnail = id;
  }

  render() {
    return (
      <div id="projects-section">
        {projects.map((data, key) => (
          <div key={key}>
            <div className="category-heading-container">
              <h5 className="category-heading">
                /&nbsp; {` ${data.category}`}
              </h5>
            </div>
            <div className="projects-grid-gallery">
              {data.projects.map((data, key) => (
                <a
                  id={`all-projects-${data.id}`}
                  href={`/${data.id}`}
                  key={key}
                  style={{
                    backgroundImage: `url(/projects/${data.id}/thumb.jpg)`,
                  }}
                  onTouchStart={() => this.onThumbnailTouchStart(data.id)}
                  className={`project-thumbnail link-no-decor ${
                    this.state[data.id] && "on-touch"
                  } 
                  ${
                    data.id === "id of proj that hould be vertical"
                      ? "row-span"
                      : "null"
                  }`}
                >
                  <div className="project-thumbnail-text-container">
                    <h5 className="project-thumbnail-text">
                      {data.name.replace("<br/>", "")}
                    </h5>
                  </div>
                  <img
                    className="thumbnail-arrow"
                    alt=""
                    src="/page_icons/white_arrow.svg"
                  ></img>
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default ProjectsSection;
