import React, { Component } from "react";
// import "./HeadingBlocks.css";
import "./SecondHeadingBlock.css";
import ReactHtmlParser from "react-html-parser";

class SecondHeadingBlock extends Component {
  state = {};
  render() {
    const first_tel = "+421 905 243 705";
    const email = "atelierdv@atelierdv.sk";

    return (
      <div className="h1-white-back-container second-heading">
        <div className="h1-container">
          <h1>{ReactHtmlParser("Neváhajte nás  <br/> kontaktovať")}</h1>
        </div>
        <div className="first-number h3-container inline contact-h3-container">
          <h3>
            <span>Telefón : </span>&nbsp;
            <a
              className="first-number-link link-no-decor"
              href={`tel:${first_tel}`}
            >
              {first_tel}
            </a>
            <span className="comma">, </span>
          </h3>
        </div>
        <a className=" link-no-decor" href={`tel:${first_tel}`}>
          <img className="call-icon" alt="" src="/page_icons/call.svg"></img>
        </a>
        <div>
          <div className="h3-container inline contact-h3-container">
            <h3>
              <span>E-mail : </span>&nbsp;
              <a className="email-link link-no-decor" href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                {email}
              </a>
            </h3>
          </div>
          <a className="link-no-decor" href="mailto:atelierdv@atelierdv.sk" target="_blank" rel="noopener noreferrer">
            <img
              className="send-email-icon"
              src="/page_icons/send_email.svg"
              alt=""
            ></img>
          </a>
        </div>
      </div>
    );
  }
}

export default SecondHeadingBlock;
