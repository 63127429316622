import React, { Component } from "react";
import "./PanoDescription.css";

class PanoDescription extends Component {
  panoScenes = [
    {
      name: "Alžbetin park Trnava",
      id: "alzbetin_park",
    },
    {
      name: "Podunajské byskupice",
      id: "biskupice",
    },
    {
      name: "Coffea Trakovice",
      id: "coffea_trakovice",
    },
    {
      name: "Kreatívne centrum Trnava",
      id: "kreativne_centrum",
    },
    {
      name: "Dolná brána",
      id: "dolna_brana",
    },
  ];

  state = {
    currentPanoScene: this.panoScenes[0],
  };

  constructor(props) {
    super(props);

    this.handleEventPanoSceneChanged = this.handleEventPanoSceneChanged.bind(
      this
    );

    window.document.addEventListener(
      "panoSceneChanged",
      this.handleEventPanoSceneChanged,
      false
    );
  }

  handleEventPanoSceneChanged(e) {
    const index = e.detail["currentSceneIndex"];
    this.setState({ currentPanoScene: this.panoScenes[index] });
  }

  render() {
    return (
      <div>
        <a
          href={`/${this.state.currentPanoScene.id}`}
          className="pano-description-container link-no-decor"
        >
          <h4 className="pano-description">
            {this.state.currentPanoScene.name}
          </h4>
        </a>
      </div>
    );
  }
}

export default PanoDescription;
