import React, { Component } from "react";
import Pano from "../Pano/Pano";
import FirstHeadingBlock from "../HeadingBlocks/FirstHeadingBlock";
import { projects } from "../../data/data";
import "./ProjectDetailPage.css";
import ProjectInfo from "./ProjectInfo/ProjectInfo";
import ProjectPhotoGallery from "./ProjectPhotoGallery/ProjectPhotoGallery";
import MetaTags from 'react-meta-tags';

class ProjectDetailPage extends Component {
  state = {};

  getProjectById(id) {
    var category;
    for (category in projects) {
      var project;
      for (project in projects[category].projects)
        if (projects[category].projects[project].id === id) {
          return projects[category].projects[project];
        }
    }
    return null;
  }

  getNextProject() {
    for (let j = 0; j < projects.length; j++) {
      for (let i = 0; i < projects[j].projects.length; i++) {
        if (projects[j].projects[i].id === this.project.id) {
          if (i + 1 >= projects[j].projects.length) {
            if (j + 1 >= projects.length) {
              return projects[0].projects[0];
            }
            return projects[j + 1].projects[0];
          }

          return projects[j].projects[i + 1];
        }
      }
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.project = this.getProjectById(this.props.match.params.id);
    if (this.project == null) {
      window.location.href = "/";
    }
  }

  render() {
    var hasMarzipano = false;
    if (this.project.has_marzipano === true) {
      hasMarzipano = true;
    }

    return (
      <div>
        <MetaTags>
          <title>Ateliér DV - {this.project.name.replace("<br/>", "")}</title>
          <meta name="description" content={this.project.describtion.replace("<br/>", "").substring(0, 150)} />
          <meta property="og:title" content={"Ateliér DV - " + this.project.name.replace("<br/>", "")} />
        </MetaTags>

        <div style={{ position: "relative" }}>
          <Pano
            hasMarzipano={hasMarzipano}
            featuredImage={`/projects/${this.project.id}/featured_image.jpg`}
            marzipanoLocation={`/projects/${this.project.id}/marzipano/index.html`}
          />
        </div>
        <div className="project-heading-and-info-container">
          <FirstHeadingBlock
            heading={this.project.name}
            project_detail="true"
          ></FirstHeadingBlock>
          <ProjectInfo project={this.project} />

          <div className="next-project-button-container">
            <a
              href={this.getNextProject().id}
              className="next-project-button link-no-decor"
            >
              <h5>Ďalší projekt</h5>
              <img
                className="next-project-arrow"
                alt=""
                src="/page_icons/arrow.svg"
              />
            </a>
          </div>
        </div>

        <ProjectPhotoGallery project={this.project} />

        <div className="all-projects-button-container">
          <a
            href={`to_all-projects-${this.props.match.params.id}`}
            className="all-projects-button link-no-decor"
          >
            <img
              className="all-projects-arrow"
              alt=""
              src="/page_icons/arrow.svg"
            />
            <h5>Všetky projekty</h5>
          </a>
        </div>
      </div>
    );
  }
}

export default ProjectDetailPage;
