import React, { Component } from "react";
import "./ProjectPhotoGallery.css";
import { getListOfImages } from "./listOfImagesHelper.js";

class ProjectPhotoGallery extends Component {
  state = {
    listOfImages: []
  };

  // This is not nice, everytime you add new project you need to run generate_list_of_images_helper.sh
  // but there no other way withou server side script
  componentDidMount() {
    this.setState({ listOfImages: getListOfImages(this.props.project.id) });
  }

  render() {
    if (this.state.listOfImages === []) {
      return (<div></div>)
    } else {
      return (
        <div className="project-photo-gallery">
          {this.state.listOfImages &&
            this.state.listOfImages.map((data, key) => (
              <img
                className={`photo  ${data.includes("_h") ? "col-span" : null}`}
                src={data}
                alt=""
                key={key}
              ></img>
            ))}
        </div>
      );
    }
  }
}

export default ProjectPhotoGallery;
