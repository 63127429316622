import React, { Component } from "react";
import Pano from "../Pano/Pano";
import ParagraphText from "./ParagraphText/ParagraphText";
import FirstHeadingBlock from "../HeadingBlocks/FirstHeadingBlock";
import ProjectsSection from "./ProjectsSection/ProjectsSection";
import "./HomePage.css";
import SecondHeadingBlock from "../HeadingBlocks/SecondHeadingBlock";
import Map from "./Map/Map";
import PanoDescription from "./PanoDescription/PanoDescription";

import { scroller } from "react-scroll";

class HomePage extends Component {

  componentDidMount() {
    if (this.props.match.params.id != null) {
      scroller.scrollTo(this.props.match.params.id, {
        duration: 400,
        smooth: "easeInOutQuart",
        offset: -70,
      });
    }
    this.props.history.replace("");
  }

  state = {};
  render() {
    return (
      <div>
        <div style={{ position: "relative" }}>
          <PanoDescription />
          <Pano
            marzipanoLocation={"panoLandingPage/index.html"}
            hasMarzipano={true}
          />
        </div>
        <div id="about-section">
          <FirstHeadingBlock
            heading={"Architektonický <br/> ateliér"}
            text={
              "Profesionálna kvalita, a vaša spokojnosť je <br/> prvoradým kritériom pri vytváraní <br/> architektonických diel."
            }
          ></FirstHeadingBlock>
          <ParagraphText
            text={
              "Ateliér DV s.r.o. Trnava pôsobí v oblasti navrhovania stavieb od roku 1997. <br/> Profesionálna kvalita, nadčasovosť návrhov založených na modernom koncepte je prvoradým kritériom pri vytváraní architektonických diel. <br/> Hlavným predmetom činnosti nášho ateliéru je komplexná projektovo - inžinierska činnosť zameraná na všetky formy výstavby. Ateliér zabezpečuje všetky stupne projektovej dokumentácie, vrátane inžinierskej činnosti a činnosti stavebného dozora. <br/> Kolektív ateliéru má štyroch výkonných projektantov - dvoch architektov, jedného stavebného inžiniera a jedného inžiniera ekonóma. Kreatívny tím ateliéru dopĺňajú stabilní odborní externí spolupracovníci.Dlhoročné skúsenosti a najmodernejšie vybavenie ateliéru sú zárukou kvalitného a včasného spracovania všetkých stupňov projektovej dokumentácie."
            }
          />
        </div>
        <ProjectsSection />

        <div id="contact-section">
          <SecondHeadingBlock />
          <Map />
        </div>
      </div>
    );
  }
}

export default HomePage;
