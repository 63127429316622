import React, { Component } from "react";
import "./ProjectInfo.css";
import ReactHtmlParser from "react-html-parser";

class ProjectInfo extends Component {
  state = {};

  render() {
    return (
      <div className="project-info-container">
        <div className="project-stats">
          <div className="grid-container">
            {this.props.project.study_year_start && <b>štúdia: </b>}
            {this.props.project.study_year_start && (
              <span>
                {this.props.project.study_year_start}
                {this.props.project.study_year_end && (
                  <span> - {this.props.project.study_year_end}</span>
                )}
                <br />
              </span>
            )}
            {this.props.project.project_year_start && <b>projekt: </b>}
            {this.props.project.project_year_start && (
              <span>
                {this.props.project.project_year_start}
                {this.props.project.project_year_end && (
                  <span> - {this.props.project.project_year_end}</span>
                )}
                <br />
              </span>
            )}
            {this.props.project.realization_year_start && <b>realizácia: </b>}
            {this.props.project.realization_year_start && (
              <span>
                {this.props.project.realization_year_start}
                {this.props.project.realization_year_end && (
                  <span> - {this.props.project.realization_year_end}</span>
                )}
                <br />
              </span>
            )}
            {this.props.project.authors && <b>autori: </b>}
            {this.props.project.authors && (
              <span className="authors-container">
                <p className="authors-list">
                  {this.props.project.authors} <br />
                </p>
              </span>
            )}
            {this.props.project.investor && <b>investor: </b>}
            {this.props.project.investor && (
              <span>{this.props.project.investor}</span>
            )}
          </div>
        </div>
        <p className="project-description">
          {/* <b>{this.props.project.tldr}</b>
          <br /> */}
          {ReactHtmlParser(this.props.project.describtion)}
        </p>
      </div>
    );
  }
}

export default ProjectInfo;
