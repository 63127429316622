import React, { Component } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

class Header extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.menuHamburgerClicked = this.menuHamburgerClicked.bind(this);
    this.mobileMenuItemClicked = this.mobileMenuItemClicked.bind(this);
  }

  state = { headerIsHidden: false, mobileMenuIsHidden: true };

  handleScroll() {
    if (this.prevScroll == null) {
      this.prevScroll = window.scrollY;
      return;
    }

    const scrollYDelta = window.scrollY - this.prevScroll;

    if (scrollYDelta * scrollYDelta > 20) {
      const { headerIsHidden: headerIsHidden } = this.state;
      const { mobileMenuIsHidden: mobileMenuIsHidden } = this.state;
      if (window.scrollY > this.prevScroll) {
        if (!headerIsHidden) {
          this.setState({ headerIsHidden: true });
        }
        if (!mobileMenuIsHidden) {
          this.setState({ mobileMenuIsHidden: true });
        }
      } else {
        if (headerIsHidden) {
          this.setState({ headerIsHidden: false });
        }
      }
    }

    this.prevScroll = window.scrollY;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  menuHamburgerClicked() {
    const { mobileMenuIsHidden: mobileMenuIsHidden } = this.state;
    if (mobileMenuIsHidden === true) {
      this.setState({ mobileMenuIsHidden: false });
    } else {
      this.setState({ mobileMenuIsHidden: true });
    }
  }

  mobileMenuItemClicked(where) {
    this.setState({ mobileMenuIsHidden: true });

    if (window.location.pathname !== "/" && window.location.pathname !== "") {
      if (where === "projects-section") {
        console.log("to_all-projects-" + window.location.pathname.substring(1));
        window.location.href =
          "to_all-projects-" + window.location.pathname.substring(1);
      } else {
        window.location.href = "to_" + where;
      }
    }
  }

  render() {
    const headerCollapsedClass = this.state.headerIsHidden
      ? "header-colapsed"
      : "";
    const mobileMenuHiddenClass =
      this.state.mobileMenuIsHidden || this.state.mobileMenuIsHidden
        ? "mobile-menu-hidden"
        : "";

    return (
      <div>
        <div className={`header-parent ${headerCollapsedClass}`}>
          <div className={`header`}>
            <h2 className="logo">
              <Link to="/" className=" link-no-decor">
                Ateliér <strong>DV</strong>
              </Link>
            </h2>

            <img
              onClick={this.menuHamburgerClicked}
              className="menu-hamburger"
              alt=""
              src="/page_icons/menu_hamburger.svg"
            ></img>
          </div>
          <div className={`mobile-menu`}>
            <div className={`mobile-menu-for-clip ${mobileMenuHiddenClass}`}>
              <div className="mobile-menu-item">
                <h5>
                  <ScrollLink
                    activeClass="active"
                    to="about-section"
                    spy={true}
                    smooth={true}
                    offset={-120}
                    duration={500}
                    onClick={() => this.mobileMenuItemClicked("about-section")}
                  >
                    O nás
                    <img
                      alt=""
                      className="mobile-menu-item-arrow"
                      src="/page_icons/triangle_arrow.svg"
                    />
                    <div className="mobile-menu-line"></div>
                  </ScrollLink>
                </h5>
              </div>
              <div className="mobile-menu-item">
                <h5>
                  <ScrollLink
                    activeClass="active"
                    to="projects-section"
                    spy={true}
                    smooth={true}
                    offset={-40}
                    duration={500}
                    onClick={() =>
                      this.mobileMenuItemClicked("projects-section")
                    }
                  >
                    Projekty
                    <img
                      alt=""
                      className="mobile-menu-item-arrow"
                      src="/page_icons/triangle_arrow.svg"
                    />
                    <div className="mobile-menu-line line-second-hack"></div>
                  </ScrollLink>
                </h5>
              </div>
              <div className="mobile-menu-item">
                <h5>
                  <ScrollLink
                    activeClass="active"
                    to="contact-section"
                    spy={true}
                    smooth={true}
                    offset={-40}
                    duration={500}
                    onClick={() =>
                      this.mobileMenuItemClicked("contact-section")
                    }
                  >
                    Kontakt
                    <img
                      alt=""
                      className="mobile-menu-item-arrow"
                      src="/page_icons/triangle_arrow.svg"
                    />
                  </ScrollLink>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
