export const projects = [
  {
    category: "Priemyselné stavby",
    projects: [
      {
        name: "Mestský priemyselný <br/>park",
        id: "mestsky_priemyselny_park",
        tldr:
          "Mestský priemyselný a technologický park | Trnava, Mikovíniho ul.",
        describtion:
          "V jestvujúcom areáli Správy mestského majetku Trnava je realizovaná adaptácia jestvujúcich a návrh nových objektov tak, aby novovytvorený celok spĺňal požiadavky pre nové účelové prevádzky s cieľom vytvoriť Mestský priemyselný a technologický park. Koncepcia funkčného využitia areálu je koncipovaná pre ľahkú sofistikovanú výrobu malých prevádzok, ktoré budú v nájomnom vzťahu s majiteľom priemyselného a technologického parku. Celkový zámer vytvoriť park predurčila koncepcia rozvoja priemyslu s uvažovanou podporou začínajúcim prevádzkam v oblasti ľahkého priemyslu. Architektonický výraz navrhovaných budov vychádza z funkcie objektov a priemyselnej lokality v ktorej sa nachádza.",

        project_year_start: 2004,
        project_year_end: 2010,
        realization_year_start: 2010,
        realization_year_end: 2011,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.arch. B.Loskot, Ing.arch. J.Takáč, Ing.arch. P.Kukučka",
        investor: "Mesto Trnava",
      },
      {
        name: "Coffea Trakovice",
        id: "coffea_trakovice",
        has_marzipano: true,
        describtion:
          "Firma COFFEA Drinks, s.r.o., Trnava má v katastri obce Trakovice vybudovaný výrobný areál na spracovanie potravinárskych výrobkov. Areál bol postupne vybudovaný v priebehu desiatich rokov. Areál pozostáva z dvoch hál – sirupovej haly a  skladovej haly, manipulačnej a parkovacej plochy. Rozširovaním sortimentu výrobných produktov, rozširovaním ponúkaných služieb a zhodnocovaním lokalizácie areálu v dôležitom urbanisticko-dopravnom centre chce investor na svojich pozemkoch postupne dobudovávať tento areál. Tomuto účelu slúži aj táto urbanisticko-architektonická štúdia ako koncepčný materiál na postupné rozpracovávanie tejto vízie.",
        study_year_start: 2019,
        study_year_end: 2020,
        authors: "Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko",
        investor: "COFFEA Drinks s.r.o., Trnava",
      },
      {
        name: "Administratívna budova",
        id: "administrativna_budova_boge",
        describtion:
          "Administratívna budova spoločnosti ZF BOGE Slovakia a.s. | Trnava, <br/> Ateliér DV s.r.o., realizoval: <br/>- realizačný projekt",
        project_year_start: 2013,
      },
      {
        name: "Hala Štitáre",
        id: "hala_stitare",
        describtion: "",
        authors: "",
        investor: "",
      },
    ],
  },
  {
    category: "Bytové stavby",
    projects: [
      {
        name: "Alžbetin park Trnava",
        id: "alzbetin_park",
        describtion: "Urbanisticko - architektonická  štúdia  Obytný súbor  - Alžbetin park – Trnava – overuje možnosti vytvorenia obytného súboru v tomto území. Na vymedzenom území sú postavené objekty, ktoré súžili ako občianska vybavenosť – stravovacie a občerstvovacie zariadenie vo vzťahu ku parku. Jestvujúce územie a objekty  sú využívané ako skladové priestory a baliareň sušeného ovocia. Územie má veľký rozvojový potenciál vzhľadom na dostuposť k centru mesta a dostupnosť na občiansku vybavenosť. Investor má zámer predložiť na základe vyššie uvedeného na schválenie  zmenu funkciu územia z plôch občianskej vybavenosti na funkciu viacpodlažnej zástavby bytových domov a občianskej vybavenosti. Pri architektonickom a stavebnom stvárnení objektov bude potrebné zabezpečiť podmienky na to, aby architektúra  objektov tvorila kvalitný, výrazovo kompaktný bytový objekt so zeleňou a drobnou architektúrou. Obytný súbor je  vytvorený s troma bodovými domami s polyfunkciou, ktorá je orientovaná na južnú stranu. Medzi domami sa nachádzajú plochy pre ihriská, zeleň. Statická doprava je situovaná od severu v kontakte s prístupovou komunikáciou. Zeleň bude naväzovať na plochy zelene v staničnom parku a jestvujúcu zeleň v riešenom území.",
        has_marzipano: true,
        study_year_start: 2019,
        authors: "Ing. arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko",
        investor: "Vetter Slovakia s.r.o.",
      },
      {
        name: "Botanická",
        id: "botanicka",
        describtion:
          "Polyfunkčný bytový dom je riešený ako samostatný obytný súbor v jestvujúcej zástavbe sídliska Prednádražie v bezprostrednom dotyku s botanickou záhradou. Navrhované architektonické riešenie vhodne zapadá svojou hmotou, podlažnosťou, a použitými materiálmi do jestvujúcej zástavby. Polyfunkčný bytový dom svojim tvarom vytvára vlastné polouzavreté átrium s oddychovou zeleňou a detskými ihriskami. Objekt má jedno pozemné podlažie a tri až osem nadzemných podlaží. V polyfunkčnom bytovom dome je riešených 150 bytov, obchodné priestory na prízemí, 150 parkovacích státí v suteréne a 18 parkovacích státí na teréne.",
        project_year_start: 2005,
        project_year_end: 2007,
        realization_year_start: 2008,
        realization_year_end: 2010,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "MBM STAV, Námestovo",
      },
      {
        name: "Koniarekova II",
        id: "koniarekova_2",
        describtion:
          "Polyfunkčný bytový dom je riešený ako 1.etapa nového obytného súboru Koniarekova. Tento súbor je riešený ako uzáver pôvodného obytného súboru zo 70-tych rokov minulého storočia. Pozdĺžna hmota objektu tvorí protiváhu k pôvodným bodovým osempodlažným bytovým domom. Pozdĺžna hmota je oživená výškovou gradáciou smerom ku Koniarekovej ulici s vyvrcholením vo forme bodového domu ako analógia s pôvodnými objektami. Výškové členenie je postupne od osempodlažnej hmoty bodového charakteru po šesť až štvorpodlažnej pozdĺžnej sekciovej hmoty. Pozdĺžna hmota je na konci zalomená, čím vytvára priehľadové uvoľnenie medzi objektmi I. a II. etapy. V polyfunkčnom bytovom dome je riešených 96 bytov, obchodné priestory na prízemí, 50 parkovacích státí v suteréne a 68 parkovacích státí na teréne.",
        project_year_start: 2005,
        project_year_end: 2007,
        realization_year_start: 2007,
        realization_year_end: 2008,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "EWD, Bratislava",
      },

      {
        name: "Koniarekova I",
        id: "koniarekova_1",
        describtion:
          "Polyfunkčný bytový dom je riešený ako 1.etapa nového obytného súboru Koniarekova. Tento súbor je riešený ako uzáver pôvodného obytného súboru zo 70-tych rokov minulého storočia. Pozdĺžna hmota objektu tvorí protiváhu k pôvodným bodovým osempodlažným bytovým domom. Pozdĺžna hmota je oživená výškovou gradáciou smerom ku Koniarekovej ulici s vyvrcholením vo forme bodového domu ako analógia s pôvodnými objektami. Výškové členenie je postupne od osempodlažnej hmoty bodového charakteru po šesť až štvorpodlažnej pozdĺžnej sekciovej hmoty. Pozdĺžna hmota je na konci zalomená, čím vytvára priehľadové uvoľnenie medzi objektmi I. a II. etapy. V polyfunkčnom bytovom dome je riešených 96 bytov, obchodné priestory na prízemí, 50 parkovacích státí v suteréne a 68 parkovacích státí na teréne.",
        project_year_start: 2005,
        project_year_end: 2007,
        realization_year_start: 2007,
        realization_year_end: 2008,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "EWD, Bratislava",
      },

      {
        name: "Malý paríž",
        id: "maly_pariz",
        describtion:
          "Polyfunkčný bytový dom je riešený na zasanovanej časti pôvodnej parcelácie v CMZ Trnava. Historická stopa pôvodných domov bola zohľadnená aj pri architektonickom návrhu. Navrhované architektonické riešenie vhodne zapadá svojou hmotou, podlažnosťou, a použitými materiálmi do jestvujúcej historickej zástavby CMZ Trnava. Polyfunkčný bytový dom svojim tvarom vytvára tri uzatvorené átriá presvetlené veľkoplošnými svetlíkmi. Objekt má jedno podzemné podlažie a tri nadzemné podlažia a podkrovie. V polyfunkčnom bytovom dome je riešených 61 bytov, obchodné priestory na prízemí a 99 parkovacích státí v suteréne.",
        project_year_start: 2005,
        project_year_end: 2008,
        realization_year_start: 2008,
        realization_year_end: 2011,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.arch. Peter Purdeš, Ing.Jozef Ďurko",
        investor: "Eurodom 3, Trnava",
      },
      {
        name: "Vodáreň hliny",
        id: "vodaren",
        describtion:
          "Polyfunkčný bytový dom je riešený ako súčasť obytného súbor Vodáreň II – Hliny. Navrhované architektonické riešenie svojou hmotou vytvára akcent a nástup do obytného súboru. Objekt má osem nadzemných podlaží. V polyfunkčnom bytovom dome je riešených 72 bytov, obchodné priestory a priestory služieb na prízemí.",
        project_year_start: 1996,
        project_year_end: 1997,
        realization_year_start: 1997,
        realization_year_end: 1998,
        authors: "arch. Jozef Ďurko, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "BRICON, Trnava",
      },
      {
        name: "Ave Galanta",
        id: "ave_galanta",
        describtion:
          "Ateliér DV s.r.o., realizoval:<br/>- štúdia<br/>- projekt pre stav. povolenie<br/>- realizačný projekt<br/>- autorský dozor",
        project_year_start: 2007,
        project_year_end: 2012,
        realization_year_start: 2012,
        realization_year_end: 2014,
        authors: "",
        investor: "",
      },
      {
        name: "Hornopotočná Trnava",
        id: "hornopotocna",
        describtion:
          "Architektonicko-hmotové riešenie riešených bytových domov vychádza z podmienok zástavby určených Územným plánom CMZ Trnava. Urbanistické riešenie vychádza z princípu náznaku artikulácie pôvodného spôsobu zástavby, t.j. vytvára hlavné dominantné uličné krídlo na ktoré nadväzujú sekundárne dvorové krídla. Navrhovaná podlažnosť vytvára uličné krídlo s podkrovím. Vnútrodvorové krídla sú riešené ako jednopodlažné s podkrovím. Hlavné uličné fasády sú architektonicky členené arkiermi, ktoré sú situované nad prejazdami.",
        project_year_start: 2006,
        project_year_end: 2007,
        realization_year_start: 2007,
        realization_year_end: 2009,
        authors: "arch. Jozef Ďurko, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "súkromná osoba",
      },
    ],
  },
  {
    category: "Verejené stavby",
    projects: [
      {
        name: "Kaufland Trnava",
        id: "kaufland",
        describtion: "",
        project_year_start: 0,
        project_year_end: 0,
        realization_year_start: 0,
        realization_year_end: 0,
        authors: "",
        investor: "",
      },
      {
        name: "Radnica Skalica",
        id: "radnica_skalica",
        describtion:
          "Budova radnice v Skalici bola postavená v 17. storočí. Pôvodne neskororenesančný objekt, ktorý má staršie základy, bol v 18. storočí prefasádovaný a v 19. storočí upravený. K pôvodnému historickému objektu, ktorý sa zrekonštruoval, je pristavená novostavba. Architektonický výraz novostavby sa prispôsobil historickej budove a danému prostrediu. Novými výrazovými a materiálovými prostriedkami novostavba obohacuje jestvujúcu urbanistickú štruktúru. V objekte bola vytvorená nová zasadačka a administratívne priestory. Vnútorný dvor je dotvorený ako oddychovo relaxačná zóna s fontánou. Rekonštrukcia a dostavba radnice v Skalici bol ocenená cenou Stavba roka 1996.",
        project_year_start: 1992,
        project_year_end: 1994,
        realization_year_start: 1994,
        realization_year_end: 1996,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "Mesto Skalica",
      },
      {
        name: "Športová hala Cífer",
        id: "sportova_hala",
        describtion:
          "Ateliér DV s.r.o., realizoval: <br/> - urbanisticko-architektonickú štúdiu, <br/> - projekt pre územné povolenie <br/>  - projekt pre stavebné povolenie",
        study_year_start: 2013,
        project_year_start: 2014,
        project_year_end: 2016,
        realization_year_start: 2016,
        realization_year_end: 2019,
        authors:
          "Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko, Ing.arch. Ľuboš Vagala",
        investor: "Obec Cífer",
      },
      {
        name: "Amfiteáter Trnava",
        id: "amfiteater",
        describtion:
          "Predmetom rekonštrukcie amfiteátra bol vstupný objekt s prestrešením, premietacia kabína, samotné hľadisko s prestrešením, premietacia plocha, javisko s priľahlým objektom, úprava celého areálu, oplotenie a kaplnky pri oplotení. Navrhované riešenie svojim architektonickým výrazom a použitými materiálmi zušľachťuje amfiteáter na vyššiu kvalitatívnu úroveň. Koncepcia amfiteátru je založená príjemnom zážitku z návštevy podujatí a zároveň na sprístupnení celého areálu návštevníkom aj mimo akcií. Je tu vybudovaná kaviareň, bufet, parková úprava s detským ihriskom a fontána.",
        project_year_start: 2004,
        project_year_end: 2005,
        realization_year_start: 2005,
        realization_year_end: 2006,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Peter Purdeš, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko, Ing.arch. Ľuboš Vagala",
        investor: "Mesto Trnava",
      },
      {
        name: "Materská škola<br/> Ivanka pri Dunaji",
        id: "materska_skola_ivanka",
        describtion: "",
        authors: "",
        investor: "",
      },
      {
        name: "Centrum hurbanova",
        id: "centrum_hurbanova",
        describtion:
          "Architektonické členenie hmoty objektu zodpovedá jeho funkcii, urbanistickému situovaniu v danom priestore a vytvára oživujúci prvok dotvárajúci prostredie v organizme mesta. V objekte sa nachádzajú maloobchodné prevádzky a administratívne priestory. Centrum Hurbanova tvoria tri samostatné objekty, ktoré tvoria jednu stavebnú čiaru rovnobežnú s Hurbanovou ulicou. Smerom od severu sú objekty dvojpodlažné a postupne podlažnosť stúpa až na päť. Prevádzky budú orientované smerom do ulice atraktívnou presklenou fasádou. V zadnej časti je parkovanie návštevníkov a zásobovanie prevádzok.",
        project_year_start: 2006,
        project_year_end: 2008,
        realization_year_start: 2008,
        realization_year_end: 2010,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "Colfin s.r.o., Senica",
      },
      {
        name: "Pohoda",
        id: "pohoda",
        describtion:
          "Viacúčelová športová hala je zrealizovaná v rámci športového areálu Slávia. Dynamický architektonický výraz charakterizuje funkciu objektu. V hale sú riešené priestory pre fitnes, posilovňu, squash, saunu, bufet, administratívu a sociálne zázemie návštevníkov a zamestnancov.",
        project_year_start: 2000,
        project_year_end: 2001,
        realization_year_start: 2001,
        realization_year_end: 2002,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "Združenie podnikateľov, Trnava",
      },

      {
        name: "SPP Nové mesto n/V.",
        id: "spp_nove_mesto",
        describtion:
          "Technicko-prevádzková budova je postavená ako samostatným objekt v rámci areálu budov SPP OZ v Novom Meste n/Váhom. Objekt je osadený na exponovanej polohe pri hlavnom cestnom ťahu na Moravu. Z tohto dôvodu je aj architektonický výraz riešenej stavby vytvorený štvorpodlažnou oblou valcovitou plochou s reflexnou závesovou presklenou fasádou. V objekte sú umiestnené technicko-admnistratívne prevádzky so zázemím.",
        project_year_start: 1995,
        project_year_end: 1996,
        realization_year_start: 1996,
        realization_year_end: 1997,
        authors: "arch. Jozef Ďurko, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "SPP Bratislava",
      },
      {
        name: "Tanex",
        id: "tanex",
        describtion:
          "Architektonické tvarovanie autoservisu vychádza z urbanistických a dispozično-prevádzkových podmienok riešenia celého areálu. Hlavný hmotový akcent sa sústreďuje na riešenie autosalónu, hmota ktorého tvorí nárazník pri pešom ťahu z centra. Z tohto dôvodu bolo zvolené atraktívne polkruhové riešenie s celozasklenými plochami. Zostávajúca časť autoservisu je riešená podľa typológie firmy FIAT pre servisy s architektonickým prispôsobením tvaru objektu.",
        project_year_start: 1997,
        project_year_end: 1998,
        realization_year_start: 1997,
        realization_year_end: 1998,
        authors: "arch. Jozef Ďurko, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "TANEX, Trnava",
      },
      {
        name: "Občianska vybavenosť Zátvor",
        id: "zatvor",
        describtion:
          "Ateliér DV s.r.o., realizoval: <br/> - urbanisticko-architektonickú štúdiu, <br/> - projekt pre územné povolenie SO 01 - SO 15 <br/> - projekt pre stavebné povolenie SO 01 - SO 15 <br/> - inžinierska činnosť",
        project_year_start: 2008,
        project_year_end: 2011,
      },
      {
        name: "Motel Karpaty",
        id: "motel_karpaty",
        describtion:
          "Vonkajšie stvárnenie objektu vychádza z funkcie zariadenia a so zohľadnením  hmotovo-priestorových daností lokality. Základným zámerom architektonického riešenia bolo vytvorenie výškovo kompaktnej hmoty s architektonickým akcentom schodiska. Estetické pôsobenie objektu s charakteristickými znakmi objektu motela s izbami a balkónmi bude zvýraznené členením plôch fasády.",
        study_year_start: 2016,
        project_year_start: 2019,
        authors: "Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko",
        investor: "Spectrum Group a.s., Trnava",
      },
    ],
  },
  {
    category: "Sakrálne a cirkevné stavby",
    projects: [
      {
        name: "Pastoračné centrum",
        id: "pastoracne_centrum",
        describtion:
          "Pastoračné centrum Božieho milosrdenstva je postavené v centre sídliska Prednádražie v Trnave Centrum je situované v rámci hromadnej zástavby bytových domov a občianskej vybavenosti na pozemku susediacim s botanickou záhradou. V pastoračnom centre je navrhovaný vstupný priestor so zázemím, viacúčelová sála, byt pre kňaza, technické zázemie a hlavný priestor kostola. V rámci prvej etapy je vybudovaná viacúčelová sála, vstupné priestory so zázemím a byt kňaza. V rámci tejto etapy je vybudovaná veža, ktorá vytvára výrazný architektonicko – hmotový akcent v danom prostredí. Prízemná hmota je formovaná do otvoreného oblúku, ktorý vytvára priestor na zhramažďovanie. Tento priestor je dotvorený aj dro pabnou parkovou architektúrou. Navrhovaný priestor kostola, ktorý bude vybudovaný v ďalšej etape s kapacitou cca 300 návštevníkov ma kruhový tvar s arénovým usporiadaním lavíc. V zadnej časti pozemku za kostolom je navrhovaná parková úprava so športovými plochami.",
        project_year_start: 2010,
        realization_year_start: 2010,
        realization_year_end: 2012,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "Rím.-kat. cirkev",
      },
      {
        name: "Dom nádeje",
        id: "dom_nadeje",
        describtion:
          "Navrhovaná rekonštrukcia a dostavba pôvodného domu smútku je situovaná v juhovýchodnej časti obce Hrnčiarovce v areáli cintorínu na Cintorínskej ulici. Návrh vytvára nové architektonické riešenie pôvodnej jednoduchej hranolovej hmoty oživením o kontrastnú elipsovitú hmotu obradnej siene. Pôvodná dispozícia nevyhovovala dôstojnej prevádzke domu smútku. Návrh rekonštrukcie pôvodnej časti vytvára v týchto priestoroch vstupné priestory pre smútočných hostí, miestnosť pre kňaza, sklad vencov, samostatne prístupné bezbariérové WC pre návštevníkov, sklad a miestnosť s chladiacim dvojboxom. Nová obradná sieň je prístupná vstupom s výstupom pre smútočných hostí zo strany od cintorína a zo strany od ulice pre personál a prísun rakvy na vozíku.",
        project_year_start: 2010,
        project_year_end: 0,
        realization_year_start: 0,
        realization_year_end: 0,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "Rím.-kat. cirkev",
      },
      {
        name: "Rekonštrukcia <br/> rehoľného domu",
        id: "biskupice",
        describtion: "Areál stavieb s priľahlým pozemkom sa nachádza v exponovanej polohe Podunajských Biskupíc. Areál pozostáva objektu pôvodného pamiatkovo chráneného kaštieľa, kostola a dvoch prístavieb z rokov 1924 a 1949. Objekty pôvodne slúžili ako rehoľný dom Kongregácie Milosrdných sestier sv. Kríža. Po roku 1950 areál slúžil ako nemocnica, a časť mimo objektov Kongregácie slúži aj doteraz. Objekty vo vlastníctve Kongregácie budú plniť funkciu reholného a pútnickeho domu – zariadenia cirkvi – občianskej vybavenosti celomestského a nadmestského významu. Urbanistické riešenie objektu a priľahlého pozemku bude formou rekonštrukcie a čiastočne náznakovej rekonštrukcie obnovené do poslednej koncepčnej etapy z rokov cca 1945 - 1950.",
        has_marzipano: true,
        study_year_start: 2018,
        project_year_start: 2019,
        authors: "Ing. arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing. Jozef Ďurko",
        investor: "Kongregácia Milosrdných sestier sv. Kríža – Slovenská provincia",
      },
      {
        name: "Evanielický kostol",
        id: "evanielicky_kostol",
        describtion: "",
        study_year_start: 2017,
        project_year_start: 2018,
        realization_year_start: 2019,
        authors: "Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko",
        investor: "Cirkevný zbor ECAV na Slovensku Trnava",
      },
      {
        name: "Kostol Beluša",
        id: "kostol_belusa",
        describtion:
          "Rím.-kat. kostol Sedembolestnej Panny Márie je postavený na južnom okraji obce Beluša časti Hloža v rámci nízkopodlažnej zástavby rodinných domov. Architektonický výraz objektu kostola vytvára v danom prostredí dominantu, ktorá sa uplatňuje aj v diaľkových pohľadoch z blízkej diaľnice. Z osi veže hmota kostola sa znižuje do nízkych hmôt, ktoré vytvárajú malé polouzatvorené námestie pred kostolom. Kostol s kapacitou cca 400 návštevníkov ma arénové usporiadanie lavíc, na poschodí chór pre zbor a organ. V nízkych hmotách sú z jednej strany situované priestory sakristie, spovedelnice, byt pre kňaza. V druhej časti je vytvorený viacúčelový priestor so sociálnym zázemím.",
        project_year_start: 1991,
        project_year_end: 1992,
        realization_year_start: 1992,
        realization_year_end: 1994,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "Rím.-kat. cirkev",
      },
      {
        name: "Tulipán kaplnka",
        id: "tulipan_kaplnka",
        describtion: "",
        authors: "",
        investor: "",
      },
      {
        name: "Kostnica Karner",
        id: "kostnica_karner",
        describtion: "",
        authors: "",
        investor: "",
      },
    ],
  },

  {
    category: "Pamiatkove objekty",
    projects: [
      {
        name: "Obnova námestia SNP",
        id: "snp",
        describtion:
          "Filozofia obnovy predmetného priestoru spočívala v jeho vzájomnom prepojení na okolité priestory a to na Hlavnú ulicu, nedávno zrekonštruovaný parčík pri evanjelickom kostole a Ružový park, s rešpektovaním typologických odlišností námestia a parku. Snahou na námestí bolo utlmenie dopravy a posilnenie bezpečného, bezbariérového - najviac exponovaného nástupu peších v smere od železničnej stanice do Hlavnej ulice ako pešej zóny a Streleckej ulice. Námestie bolo koncepčne prinavrátené do obdobia rannej moderny zo začiatku 20-tych rokov dvadsiateho storočia s tým, že  štúdia predpokladá zachovanie Pamätníka M. R. Štefánika v súčasnej polohe, umiestnenie sôch Panny Márie a sv. Jozefa v priestore pôvodného barokového mosta a umiestnenie Pamätníka Víťazstvo na základnú kompozičnú os parčíka v strede Námestia SNP. Nový kompozičný uzáver pešej zóny bude vytvárať priestorová prezentácia Dolnej brány. Štúdia predpokladá doplnenie múrika okolo parčíka, doplnenie bránok. ",
        study_year_start: 2016,
        study_year_end: 2019,
        authors: "Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko",
        investor: "Mesto Trnava",
      },
      {
        name: "Obnova Nyárovskej kúrie",
        id: "nyarovska_kuria",
        describtion:
          "Objekt Nyárovskej kúrie s priľahlým parkom sa nachádza v exponovanej polohe obce Bučany. Jedná sa o objekt pamiatkovo chránený – zapísaný v zozname národných kultúrnych pamiatok. Zmena stavby spočíva v komplexnej rekonštrukcii, pričom stavba bude obnovená do poslednej koncepčnej etapy z prelomu devätnásteho a dvadsiateho storočia. Jedná sa o objekt pôvodného tvaru L, samostatne stojaci, čiastočne podpivničený, zastrešený valbovou strechou. Návrh obnovy spočíva v odstránení prednej časti stavby, z výmeny nefunkčných častí, a z dotvorenia nového dispozičného riešenia. Po obnove bude objekt využívaný ako kultúrno spoločenské centrum. Súčasťou obnovy je i revitalizácia priľahlého parku. <br/> Ateliér DV s.r.o., realizoval:<br/> - štúdia, prieskumy<br/> - projekt pre stav. povolenie<br/> - realizačný projekt<br/> - autorský dozor",
        project_year_start: 2008,
        project_year_end: 0,
        realization_year_start: 2013,
        realization_year_end: 2015,
        authors:
          "arch. Jozef Ďurko, Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "Obec Bučany",
      },
      {
        name: "Dolná brána",
        id: "dolna_brana",
        has_marzipano: true,
        describtion: "",
        project_year_start: 0,
        project_year_end: 0,
        realization_year_start: 0,
        realization_year_end: 0,
        authors: "",
        investor: "",
      },
      {
        name: "Kreatívne centrum",
        id: "kreativne_centrum",
        has_marzipano: true,
        describtion:
          "Objekt meštianskeho domu na Hlavnej ulici 17 sa nachádza v Mestskej pamiatkovej rezervácii mesta Trnava, je evidovaný v ústrednom zozname pamiatkového fondu. Filozofia obnovy spočíva v záchrane havarijného stavu objektu a pamiatkovej obnove historického objektu. Základným zámerom obnovy – sanácie je prinavrátenie objektu do obdobia jeho poslednej koncepčnej úpravy. Objekt bude po obnove a rekonštrukcii podoprovať mestotvornú funkciu, ako aj architektonicky bude zveľaďovať uličný priestor na Hlavnej ulici v Trnave.",
        study_year_start: 2019,
        project_year_start: 2020,
        authors:
          "Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "Trnavský samosprávny kraj",
      },
      {
        name: "Obnova kaštieľa a parku <br/> Voderady",
        id: "voderady",
        describtion:
          "Urbanistické riešenie objektu a priľahlého parku bude formou rekonštrukcie a čiastočne náznakovej rekonštrukcie obnovené do poslednej koncepčnej etapy z rokov 1864-1880. Nakoľko sa objekt nachádza v centrálnej polohe obce, bude plniť funkciu aj kultúrno- spoločenského centra. Objekt je pamiatkovo chránený – je zapísaný v zozname národných kultúrnych pamiatok. ",
        study_year_start: 2017,
        project_year_start: 2018,
        project_year_end: 2020,
        authors:
          "Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko, Ing.Jozef Ďurko",
        investor: "Bohdal s.r.o.,Bratislava",
      },
      {
        name: "Informačný systém <br/>Kopčany",
        id: "informacny_system_kopcany",
        describtion:
          "Jedným z najvýznamnejších terajších centier spojených aj s pôsobením kresťanskej misie Konštantína a Metoda bolo hradisko ležiace pri rieky Moravy medzi obcami Mikulčice a Kopčany. Lokalita je dnes unikátna svojimi archeologickými nálezmi a má štatút národnej kultúrnej pamiatky a ašpiruje na zápis do zoznamu pamiatok UNESCO. Zdieľanie takého spoločného kultúrneho dedičstva sa premietlo do zámeru vybudovať cezhraničný Archeopark Mikulčice - Kopčany, ktorý by prezentoval toto územie. V lokalite Mikulčíc je už vybudovaná moderná expozícia s návštevníckym centrom a v pláne sú ďalšie rozvojové projekty. V tomto duchu sa aj slovenská strana usiluje o oživenie a sprístupnenie významných pamiatok v lokalite Kopčian. Súčasťou týchto snáh je aj tento ideový materiál urbanisticko-architektonickej štúdie Informačného systému Archeoparku Mikulčice – Kopčany.",
        study_year_start: 2019,
        authors: "Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko",
        investor: "Obec Kopčany",
      },
      {
        name: "Adalbertínum",
        id: "adalbertinum",
        has_marzipano: true,
        describtion: "",
        authors: "",
        investor: "",
      },
      {
        name: "Hradby Trnava",
        id: "hradby_trnava",
        describtion: "",
        authors: "",
        investor: "",
      },
      {
        name: "Dom na Pekárskej <br/> ulici",
        id: "dom_na_pekarskej",
        describtion:
          "Ateliér DV s.r.o., realizoval: <br/>- architektonická štúdia <br/>- jednostupňový projekt <br/>- autorský dozor <br/>- technický dozor",
        project_year_start: 2013,
        realization_year_start: 2013,
        realization_year_end: 2014,
      },
      {
        name: "Farský úrad",
        id: "farsky_urad",
        describtion:
          "Ateliér DV s.r.o., realizoval: <br/>- dispozično-architektonické riešenie interiéru objektu <br/>- projekt pre stavebné povolenie <br/>- inžinierska činnosť <br/>- autorský dozor <br/>- technický dozor",
        project_year_start: 2009,
        realization_year_start: 2009,
      },
      {
        name: "Objekt Suchá <br/> nad Parnou",
        id: "objekt_sucha",
        describtion:
          "Ateliér DV s.r.o., realizoval: <br/> - architektonickú štúdiu, prieskumy <br/> - projekt pre stavebné povolenie <br/> - inžiniersku činnosť",
        project_year_start: 2014,
      },
      {
        name: "Objekt Františkánska <br/> ulica",
        id: "objekt_frantiskanska",
        describtion:
          "Ateliér DV s.r.o., realizoval: <br/> - architektonická štúdia <br/> - jednostupňový projekt <br/> - autorský dozor",
        project_year_start: 2006,
        project_year_end: 2006,
      },
      {
        name: "Kaštieľ Hubice",
        id: "kastiel_hubice",
        describtion: "",
      },
    ],
  },
  {
    category: "Štúdie",
    projects: [
      {
        name: "Polyfunkčný objekt <br/> coffea Trnava",
        id: "coffea_trnava",
        study_year_start: 2019,
        describtion: "Urbanisticko - architektonická štúdia polyfunkčného objektu v Trnave na Orolskej ulici  overuje možnosti konverzie jestvujúceho objektu, ktorý slúžil na obchodné účely, na využitie pre stravovanie a ubytovanie. Pôvodná urbanistická koncepcia riešenia jestvujúceho objektu zostáva zachovaná. Zostávajú urbanistické väzby k okoliu - zostáva zachovaný vstup do objektu a zásobovanie. Nový urbanistický koncept riešenia je zapojený do verejného priestoru pešieho ťahu. Tento priestor je revitalzovaný vytvorením vonkajšej prevádzky obslužného priestoru so sedením a zázemím, ako sezónna demontovateľná jednoduchá stavba. Toto riešenie vytvára nový mestotvorný prvok a povyšuje tento priestor na vyššiu úroveň. Hmotovo - architektonicky jestvujúci riešený objekt zostáva v základnom princípe zachovaný. Návrh dotvára po architektonickej stránke objekt vybudovaním uskočeného podlažia, ktoré odľahčuje hmotu a zakonponováva je do štruktúry fasády. Jednotlivé fasády použitím spoločných prvkov sa harmonizujú.",
        authors: "Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko",
        investor: "Coffea Drinks s.r.o.",
      },
      {
        name: "Revitalizácia centrálnej <br/> zóny Trnovec",
        id: "revitalizacia_trnovec",
        study_year_start: 2017,
        describtion: "Centrána zóna obce Trnovec nad Váhom stratila počas uplynulých desaťročí svoj pôvodný historický charakter. Pôvodné centrum obce bolo vytvorené trojuholníkovým priestorom s verejno- spoločenskými objektami, plochou oddychovej a zelene a ukľudnenou dopravou. Návrh urbanisticej štúdie Revitalizácie centrálnej zóny obce Trnovec nad Váhom  z hľadiska hmotovo - priestorových a kompozičných princípov formujúcich obraz obce rešpektuje základný kompozičný princíp zóny vychádza z urbanistických a kompozičných daností obce, priestory hlavných a vedľajších kompozičných osí.  Rešpektuje genius locci obce, jeho historickú mierku a proporcie jestvujúcich urbanistických štruktúr, rešpektuje historické dominanty obce - kúriu, zvonicu a kríž, zároveň rešpektuje novodobé dominanty obce - budova Obecného úradu, Kultúrneho domu a  začleňuje ich do koncepcie. Pri návrhu je zabezpečená tvorba kvalitných verejných uzlových a líniových priestorov, zároveň je ukľudnená tranzitná verejná doprava cez centrum.",
        authors: "Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko",
        investor: "Obecný úrad Trnovec nad Váhom",
      },
      {
        name: "Revitalizácia centra <br/> obce Voderady",
        id: "revitalizacia_voderady",
        study_year_start: 2017,
        describtion: "V centre obce medzi kostolom a objektom starej školy vznikol voľný pozemok po asanácii stavieb v dotyku s jestvujúcim parčíkom s vodnou plochou. Účelom navrhovanej výstavby kultúrnej zóny v centrálnej zóne obce Voderady je rozšírenie jestvujúceho parčíku s vodnou plochou a zároveň vytvorenie verejného viacúčelového priestoru v centre obce. Ústredným prvkom je viacúčelová spevnená plocha organického tvaru s pódiom s dreveným prekrytím – altánkom na kultúrne akcie koncerty, divadlo, kino, mítingy, predajné akcie a pod. Navrhované spevnené plochy sú napojené do jestvujúcich chodníkov a vytvárajú spolu kompozičný prvok.",
        authors: "Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko",
        investor: "Obecný úrad Voderady",
      },
      {
        name: "Revitalizácia centra <br/> obce Zeleneč",
        id: "revitalizacia_zelenec",
        study_year_start: 2016,
        describtion: "Navrhovaná urbanisticko - architektonická štúdia Centra obce Zeleneč má za cieľ revitalizovať verejný priestor. Základným princípom je zorganizovanie celého priestoru do jedného celku materiálovým riešením a zároven vytvoreným samostatných zón centra. Pred kultúrnym domom je navrhovaná zhromažďovacia zóna vytvorená dlažbou rôznej štruktúry a farby. V nadväznosti na túto plochu je navrhovaná oddychovú zóna pred obecným úradom vytvorená fontánou s lavičkami, pitnou fontánou a zeleňou. Celá táto plocha je ukončená uzáverom vo forme sochy - busty rodáka Jozefa Bednárika pred vchodom do základnej školy. Za touto plochou je zorganizovaný jestvujúci park pred školou - je tu navrhovaný chodník a oddychové plochy s lavičkami. Priestor pred kostolom je navrhovaný na rozšírenie s vytvorením zhromažďovacej plochy.",
        authors: "Ing.arch. Ľuboš Vagala, Ing.arch. Pavel Ďurko",
        investor: "Obecný úrad Zeleneč",
      },
    ],
  },
  {
    category: "Rodinné domy",
    projects: [
      {
        name: "Rodinný dom <br/> Častá",
        id: "rodinny_dom_casta",
        describtion: "",
        authors: "",
        investor: "",
      },
      {
        name: "Modra harmónia <br/> prístrešok",
        id: "modra_harmonia",
        describtion: "",
        authors: "",
        investor: "",
      },
    ],
  },
];
