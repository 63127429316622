import React, { Component } from "react";
import "./Pano.css";

class Iframe extends Component {
  state = {};
  render() {
    return (
      <div>
        <iframe
          className="pano"
          src={this.props.marzipanoLocation}
          scrolling="no"
          frameBorder="no"
          allowtransparency="true"
          title="pano"
        />
      </div>
    );
  }
}

class Pano extends Component {
  state = {};

  render() {
    const FeaturedImage = (
      <div className="featured-image-container">
        <div
          className="featured-image"
          style={{
            backgroundImage: `url(${this.props.featuredImage})`,
          }}
        >
          {this.props.FeaturedImage}
        </div>
      </div>
    );

    return (
      <div>
        {this.props.hasMarzipano ? (
          <Iframe marzipanoLocation={this.props.marzipanoLocation} />
        ) : (
          FeaturedImage
        )}
      </div>
    );
  }
}

export default Pano;
