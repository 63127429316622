import React, { Component } from "react";
import "./ParagraphText.css";
import ReactHtmlParser from "react-html-parser";

class ParagraphText extends Component {
  state = {
    paragraphVisible: false,
  };

  constructor(props) {
    super(props);

    this.lessAboutUsClicked = this.lessAboutUsClicked.bind(this);
    this.moreAboutUsClicked = this.moreAboutUsClicked.bind(this);
  }

  lessAboutUsClicked() {
    console.log("less clicked");
    this.setState({
      paragraphVisible: false,
    });
  }

  moreAboutUsClicked() {
    console.log("more clicked");
    this.setState({
      paragraphVisible: true,
    });
  }

  render() {
    return (
      <div
        className={`paragraph-text-container ${
          this.props.projectDetailContext ? "project-detail-context" : "null"
        }`}
        style={{
          marginRight: this.props.marginRight,
          marginTop: this.props.marginTop,
        }}
      >
        <div
          className={`paragraph-text ${
            this.state.paragraphVisible ? "height-full " : "height-zero"
          }`}
        >
          <p>{ReactHtmlParser(this.props.text)}</p>
        </div>

        <div
          className={`more-about-us ${
            this.state.paragraphVisible ? "display-none" : "display-inline-flex"
          }`}
          onClick={this.moreAboutUsClicked}
        >
          <h6>Viac o nás</h6>
          <img className="right-arrow" alt="" src="/page_icons/arrow.svg"></img>
        </div>
        <div
          className={`less-about-us ${
            this.state.paragraphVisible ? "display-inline-flex" : "display-none"
          }`}
          onClick={this.lessAboutUsClicked}
        >
          <img className="left-arrow" alt="" src="/page_icons/arrow.svg"></img>
          <h6>Menej</h6>
        </div>
      </div>
    );
  }
}

export default ParagraphText;
