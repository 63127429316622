import React, { Component } from "react";
import Header from "./components/Header/Header";
import Menu from "./components/Menu/Menu";
import HomePage from "./components/HomePage/HomePage";
import ProjectDetailPage from "./components/ProjectDetailPage/ProjectDetailPage";
import "./App.css";
import "typeface-roboto";
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MetaTags from "react-meta-tags";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";

hotjar.initialize(2247027, 6);

ReactGA.initialize("G-M6X1LCP905");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  state = {};
  render() {
    return (
      <Router>
        <div>
          <Header />
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/to_:id" exact component={HomePage} />
            <Route path="/:id" component={ProjectDetailPage} />
          </Switch>
          <Menu />
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
