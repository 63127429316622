import React, { Component } from "react";
import "./Map.css";

class Map extends Component {
  state = {};
  render() {
    return (
      <div className="map-container">
        <p>
          ATELIÉR DV, s.r.o.
          <br />
          Hornopotočná 20
          <br />
          Trnava 917 01
          <br />
          Slovensko
        </p>
        <iframe
          frameBorder="0"
          className="map"
          title="map"
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDdxVAQswsoVB6QntmWLH_FWJ7euAP76Jw&q=Ateliér+Dv,+S.r.o.,+Hornopotočná+208,+917+01+Trnava&zoom=15"
          allowFullScreen
        ></iframe>
      </div>
    );
  }
}

export default Map;
